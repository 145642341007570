.swiper-button-next,
.swiper-button-prev {
 color: #99CC00 !important; 
 height: 5px;
}

.swiper-container {
    width: 50%; 
    margin: auto;
    height: auto;
}

.swiper-slide {
    width: auto;
    height: 8px;

}

.swiper-container img {
    width: 100%;
    height: auto;
}


.swiper-container .swiper-scrollbar-drag {
    background: #99CC00; 
}

.swiper-container .swiper-scrollbar-track {
    background: #D9D9D9; 
}

.swiper-container::-webkit-scrollbar {
    width: 8px;
}

.swiper-container::-webkit-scrollbar-thumb {
    background-color: #99CC00;
    border-radius: 4px;
}

.swiper-container::-webkit-scrollbar-track {
    background-color: #D9D9D9;
}
